@import './src/scss/variables';
@import './src/scss/game';

.jigsaw-puzzle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;

	&__title {
		font-size: $font-size4;
		margin-bottom: 1rem;
		font-weight: 600;

		&_major {
			font-size: $font-size5;
      margin: 0;
      font-weight: 600;
		}
	}

	&__dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
	}

	&__custom-icon, &__random-icon {
		background: $color6;

		a {
			width: 320px;
			height: 200px;
			cursor: pointer;
			justify-content: center;
			align-items: center;

			.fa-light {
				font-size: $font-size7;
			}
		}
	}

	&__week {
		&-list {
			display: flex;
			flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
		}
	}

	&__tags {
		display: flex;
    flex-direction: column;
    align-items: center;

		&-list {
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: center;
	    gap: .5rem 1rem;
		}
	}

	&__puzzles {
		&-list {
			display: flex;
			flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 1rem;
		}
	}

	&__info {
		display: flex;
    //margin: 1rem 1rem 0;
    //padding: 0 1rem;
    padding: 1rem 2rem 0;
    justify-content: center;
    //max-width: 1200px;
    flex-wrap: wrap;
    //gap: 1rem;

		&-tags {
	    display: flex;
	    align-items: center;
	    gap: .5rem;

			&_list {
				display: flex;
		    flex-wrap: wrap;
		    justify-content: center;
		    //gap: 5.25px;

				li {
					display: flex;
			    flex-wrap: wrap;
				}
			}
		}
	}

	&__dashboard,
	&__week,
	&__puzzles-list {
	  img {
	    width: 320px;
	    height: 200px;
	    object-fit: cover;
      //padding: .25rem;
	  }
	}
}

// ADVANCED

body.play {
  height: 100vh;

  .container {
    padding: 0;
    margin: unset;
    display: inline;
    max-width: 100%;
  }
}

.hide {
  display: none;
}

.clear {
  clear: both;
}

div#puzzle {
  width: 100%;
  //height: calc(100% - 109px);
  background: $color6;
  position: relative;
}

canvas#canvas, canvas#buffer, canvas#image-ghost {
  position: absolute;
  left: 0;
}

canvas.loading {
  //visibility: hidden;
}

canvas#buffer {
}

canvas#image-ghost.show {
  left: 50%;
  opacity: 1;
  top: -16px;
  pointer-events: none;
}

canvas#image-preview {
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  z-index: 100;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 300px;
}

ul.modal__item-list {
  list-style: none;
  display: inline;
  margin: 0;

  li {
    display: inline;
  }
}

.upload-image {
	cursor: pointer;
	border: 1px dashed;
	border-radius: $border-radius;
	height: $max-height;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: .5rem;

	&:hover {
		opacity: $opacity;
	}
}

.puzzle__button {
  touch-action: manipulation;
  position: absolute;
  z-index: 100;
  display: none;
  height: 2rem;
  width: 2rem;
  font-size: $font-size;
  background: $color;
  color: $color2;
}

.puzzle__button#pan-left {
  top: 55%;
  left: 0;
}

.puzzle__button#pan-right {
  top: 55%;
  right: 0;
}

.puzzle__button#pan-down {
  bottom: 0;
  left: 50%;
}

.puzzle__button#pan-top {
  top: 0;
  left: 50%;
}

.info-container__item:first-child {
	padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

@import 'dark-theme';