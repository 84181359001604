@import './src/scss/variables';

.dark-theme {
	div#puzzle {
    background: $dark-color6;
	}

	canvas#canvas {
	  background: $dark-color6;
	}

	.puzzle__button {
	  background: $dark-color;
	  color: $dark-color2;
	}
	.jigsaw-puzzle__custom-icon,
	.jigsaw-puzzle__random-icon {
		background: $dark-color6;
	}
}